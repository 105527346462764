:root {
  --almost-white: rgb(200, 200, 200);
  --almost-white-hover: rgb(230, 230, 230);
  --almost-white-active: rgb(250, 250, 250);
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(35, 35, 35);
  }
  
  * {
    box-sizing: border-box;
  }
    
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid gray;
}

.error_image {
    background: no-repeat center fixed url("https://lh3.googleusercontent.com/TdtOs4f7X42-bjL3H0TJQOEI39M4PUpyx58Urs7OIqhJONgObWbnoGwYtDawTgyAY_e9PwJk1A0YcXuvQDdIF45ioUoBzLhoF4x3zZuFcY2G0Z0p_sCToINM9EpE2264FgHPfU50Scg=w2000-h1334-no"); 
    background-size: cover;
    height: 95vh;
    width: 100%;
    position: relative;
    filter: opacity(70%);
}

.error_text {
  text-align: left;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -3%);
  color: white;
  font-size:18px;  
}

  .overlay {
    background: rgba(0, 0, 0, 0.30);
    border-radius: 12px;
    font-size: 18px;
    position: absolute;
    vertical-align: bottom;
    bottom: 20px;
    left:15px;
    padding: 4px 10px;
  }

.image_frame 
{
  color: var(--almost-white);
    padding: 10px;    
}

/*426px 240px, 640px 360px, 853px 480px, 1280px 720px, 1920px 1080px*/

.video_frame 
{
  width: 640px;
  height: 360px;

  color:var(--almost-white);
    padding: 0px;  
    margin: 0px;     

    border-color: black;
    background-color:black;
    
    border-radius: 12px;
    font-size: 10px;

    box-shadow: 0px;

    border:2px;
}

.video_frame_one 
{
  width: 1280px;
  height: 720px;

  color:var(--almost-white);
    padding: 0px;  
    margin: 0px;    

  border-color: black;
  background-color:black;
  
  border-radius: 12px;
  font-size: 10px;

  box-shadow: 0px;

  border:2px;
}

.column_video
{
  padding: 10px;
}

.image_frame:hover {
  color: var(--almost-white-hover);
}

.column img {
    
    width: 100%;
    border-radius: 12px;
    transition-duration: 1s;
    transition-property: box-shadow;
    font-size: 10px;
}

.video_ref {
  cursor: pointer;
}

.column {
    margin-bottom: auto;
    position: relative;
    flex: 25%;
    max-width: 25%;    
    transition-duration: 1s;
    transition-property: background-color;
    border-radius: 12px;
}

.column:hover { 
    background-color: rgb(66, 64, 85);
    border-radius: 12px;
}

.column:hover img{
    box-shadow: 5px 5px black;
}

@media screen and (max-width: 2200px) {
  .video_frame_one {
      width: 1920px;
      height: 1080px;
  }
}

@media screen and (max-width: 2000px) {  
  .video_frame_one {
    width: 1280px;
    height: 720px;
}
  .column {
        flex: 33%;
        max-width: 33%;
  }
}

@media screen and (max-width: 1290px) {
  .video_frame_one {
      width: 853px;
      height: 480px;
  }
}

@media screen and (max-width: 1200px) {
  .column {
        flex: 50%;
        max-width: 50%;
  }
}
 
@media screen and (max-width: 860px) {
  .video_frame_one {
    width: 640px;
    height: 360px;
  }
}

@media screen and (max-width: 650px) {
  .video_frame_one {
    width: 426px;
    height: 240px;
  }
}

@media screen and (max-width: 560px) {
  .column {
        flex: 100%;
        max-width: 100%;
        padding: 0 0px;
  }
}

@media screen and (max-width: 460px) {
  .video_frame_one {
    width: 380px;
    height: auto;
  }
}

@media screen and (max-width: 380px) {
  .video_frame_one {
    width: 280px;
    height: auto;
  }
}

.footer {
  text-align: right;
  color: gray;
  padding: 10px;
  position: relative;  
}

.footer a {
  color:var(--almost-white);
}

.footer a:hover {
  color:var(--almost-white-hover);
}

.bg-img {
  background-image: url("https://lh3.googleusercontent.com/iWLylbtvBvL_4pD1zgQJCG1Z_su9TnFI3mkdCOMUfg0_5WL5toWmGxv02I6vEA_2Rglz6qUmhf2SfGdLHZookCWqvImICbn4oyOzYa5rimKys3CGyUOIANtUVLqUfFRomZbJX5OYdOjB3z_6BY1-vNRx2F0rf2cm1VT7p8Fh7WFe1WY-jhmLEsIZo6QLWZpdB3xp5D4QbYqUc_J6tLGznM2wxikRtv_ndAf3w3XxCpw_ygD44ts8QWjuS5zMuh80YqJrYDz3LA7QzEp4cZpa8LOJWKav3oNtZqTMlwfobS5EW1ERZfqkvN-TJ5vPdCMxFyfhcuO8Pp-serTQmibe__gnLSUHf2CRVoIBdvT2jlBP2i-RXT3tiN2qjh37AdHybso3ibdOmEXX7LxzFzSxbpbd8GagnwsLNz5OrUzC-e7igAHRpUSl9B9iphDcdmsZyd489eHUO-5_CenQ5eg1R2YsfI4umIWwiSDzbAvV41SLsFWWcL6YAJZ8EJ2J7o8NZj1DxXNsc8BP1L2Gb9QD2he0K7bQlvlUNB4v2-RmrVX3qj4FH-7oANpp24F0oOn1MmN8Ue0KbzTXzwizrcTCxE2eLa_XEWF7vMeSdB43XLoWZh0oGsBJ9F8nbBOvUldRMB7T762lxiNCDgnY6wubJziU9diogr8nDM_nFrYByb8axX9yThk-NzhD5TgWW7v7oCayKX5mo62W0A9ZVOmWrMITLg=w1689-h1057-no");
  
  min-height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 8px;

  margin-top: 1px;
  margin-bottom: 2px;
  margin-right: 20px;
  margin-left: 20px;  
}

.navcontainer {
  font-size: 22px;
  position: absolute;
  margin: 5px 5px;
  width: auto;
  font-size: 20px;    
}

.navcontainer ul {
  list-style-type: none;  
  padding: 0px;
  overflow: auto;  
}

.NavLinkStyle {
  float: left;  
  display: block;
  background-color: rgba(0, 0, 0, 0.2);   
  border-radius: 8px;   
  
  text-align: center;
  padding: 10px 68px;
  text-decoration: none;
  
  color: var(--almost-white);
}


.NavLinkStyleActive {
  border: 1px solid rgb(190, 190, 190);
  border-radius: 8px;
  background-color: rgba(35, 35, 35, 0.3);  
  color:var(--almost-white-hover);
}

.NavLinkStyle:hover {  
  color:var(--almost-white-active);
}


@media screen and (max-width: 560px) {
  .NavLinkStyle {
    width: 100%; 
  }
  .navcontainer {
    transform: translate(0%, -15%);
  }
  .bg-img {
    background-size: fixed;
  }
}

